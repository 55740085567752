<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">

            <!--<a-col :md="6" :sm="24">-->
              <!--<a-form-item :label="$t('活动记录.活动编号')" prop="id">-->
                <!--<a-input v-model="queryParam.id" @pressEnter="handleQuery" :placeholder="$t('通用.输入.请输入')+$t('活动记录.活动编号')" allow-clear/>-->
              <!--</a-form-item>-->
            <!--</a-col>-->
            <!--<a-col :md="6" :sm="24">-->
              <!--<a-form-item :label="$t('活动记录.活动名称')" prop="activityName">-->
                <!--<a-input v-model="queryParam.activityName" @pressEnter="handleQuery" :placeholder="$t('通用.输入.请输入')+$t('活动记录.活动名称')" allow-clear/>-->
              <!--</a-form-item>-->
            <!--</a-col>-->
            <!--<a-col :md="6" :sm="24">-->
              <!--<a-form-item :label="$t('活动记录.开始时间')" prop="startTime">-->
                <!--<a-date-picker style="width: 100%" v-model="queryParam.startTime"-->
                               <!--:show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"-->
                               <!--valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
              <!--</a-form-item>-->
            <!--</a-col>-->
            <!--<a-col :md="6" :sm="24">-->
              <!--<a-form-item :label="$t('活动记录.结束时间')" prop="endTime">-->
                <!--<a-date-picker style="width: 100%" v-model="queryParam.endTime"-->
                               <!--:show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"-->
                               <!--valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
              <!--</a-form-item>-->
            <!--</a-col>-->
            <!--<a-col :md="6" :sm="24">-->
              <!--<a-form-item :label="$t('活动记录.活动类型')" prop="status">-->
                <!--<a-select :placeholder="$t('通用.输入.请选择')+$t('活动记录.活动类型')" style="width: 100%" v-model="queryParam.activityType" allowClear>-->
                  <!--<a-select-option v-for="(item, index) in this.activityTypeList" :value="item.id"-->
                                   <!--:key="index">-->
                    <!--{{ item.name }}-->
                  <!--</a-select-option>-->
                <!--</a-select>-->
              <!--</a-form-item>-->
            <!--</a-col>-->
            <!--<a-col :md="6" :sm="24">-->
              <!--<a-form-item :label="$t('活动记录.状态')" prop="status">-->
                <!--<a-select :placeholder="$t('通用.输入.请选择')+$t('活动记录.状态')" style="width: 100%" v-model="queryParam.status" allowClear>-->
                  <!--<a-select-option v-for="(item, index) in this.customDict.ActivitySaleStatusEnum" :value="item.type"-->
                                   <!--:key="index">-->
                    <!--{{ item.name }}-->
                  <!--</a-select-option>-->
                <!--</a-select>-->
              <!--</a-form-item>-->
            <!--</a-col>-->
            <!--<a-col :md="!advanced && 6 || 24" :sm="24">-->
              <!--<span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">-->
                <!--<a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>-->
                <!--<a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>-->
              <!--</span>-->
            <!--</a-col>-->
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['activity:activity:add']">
          <a-icon type="plus" />{{$t('通用.按钮.新增')}}
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['activity:activity:edit']">
          <a-icon type="edit" />{{$t('通用.按钮.修改')}}
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['activity:activity:remove']">
          <a-icon type="delete" />{{$t('通用.按钮.删除')}}
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['activity:activity:export']">
          <a-icon type="download" />{{$t('通用.按钮.导出')}}
        </a-button>
        <a-button type="primary" :disabled="multiple"  @click="handleSelectExport" v-hasPermi="['activity:activity:export']">
          <a-icon type="download" />{{$t('通用.按钮.选中导出')}}
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        @change="handleSortChange"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="startTime" slot-scope="text, record">
          {{ parseTime(record.startTime) }}
        </span>
        <span slot="endTime" slot-scope="text, record">
          {{ parseTime(record.endTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="activityType" slot-scope="text, record">
              {{getCategoryNameById(record.activityType)}}
        </span>
        <span slot="status" slot-scope="text, record">
           <custom-dict-tag :options="customDict.ActivitySaleStatusEnum" :value="record.status"/>
        </span>
        <template slot="coverPic" slot-scope="text, record">
          <div>
            <img v-if="record.coverPic" :src="record.coverPic"  style="width:60px;height:62px;" @click="handleRecordPreview(record.coverPic)" alt="none"/>
          </div>
        </template>
        <template slot="posterPic" slot-scope="text, record">
          <div>
            <img v-if="record.posterPic" :src="record.posterPic"  style="width:60px;height:62px;" @click="handleRecordPreview(record.posterPic)" alt="none"/>
          </div>
        </template>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['activity:activity:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['activity:activity:edit']">
             <a-icon type="edit" />{{$t('通用.按钮.修改')}}
          </a>
          <a-divider type="vertical" v-hasPermi="['activity:activity:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['activity:activity:remove']">
            <a-icon type="delete" />{{$t('通用.按钮.删除')}}
          </a>
           <!--<a-divider type="vertical" />-->
          <!--<a @click="handleRank(record)" v-hasPermi="['activity:activity:rank']">-->
            <!--<a-icon type="eye" />{{$t('活动记录.排行')}}-->
          <!--</a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageActivity,listActivity, delActivity } from '@/api/activity/activity'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import moment from 'moment';
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import {listType} from "@/api/activity/type";

export default {
  name: 'Activity',
  components: {
    CustomDictTag,
    CreateForm,
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      activityTypeList:[],
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo : {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        activityType: undefined,
        activityName: null,
        startTime: null,
        endTime: null,
        coverPic: null,
        posterPic: null,
        posterTxt: null,
        levelIds: null,
        limitNum: null,
        description: null,
        status: undefined,
        sort: null,
        area: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: this.$t('活动记录.活动编号'),
        //   dataIndex: 'id',
        //   align: 'center'
        // },
        {
          title: this.$t('活动记录.活动类型'),
          dataIndex: 'activityType',
          scopedSlots: { customRender: 'activityType' },
          align: 'center'
        },
        // {
        //   title: this.$t('活动记录.活动名称'),
        //   dataIndex: 'activityName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('活动记录.开始时间'),
        //   dataIndex: 'startTime',
        //   scopedSlots: { customRender: 'startTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('活动记录.结束时间'),
        //   dataIndex: 'endTime',
        //   scopedSlots: { customRender: 'endTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('活动记录.活动封面图'),
        //   dataIndex: 'coverPic',
        //   scopedSlots: {customRender: 'coverPic'},
        //   align: 'center'
        // },
        // {
        //   title: this.$t('活动记录.分享海报图'),
        //   dataIndex: 'posterPic',
        //   scopedSlots: {customRender: 'posterPic'},
        //   align: 'center'
        // },
        /*{
          title: this.$t('活动记录.最多参与次数'),
          dataIndex: 'limitNum',
          ellipsis: true,
          align: 'center'
        },*/
        // {
        //   title: this.$t('活动记录.状态'),
        //   dataIndex: 'status',
        //   scopedSlots: { customRender: 'status' },
        //   align: 'center'
        // },
        {
          title: this.$t('通用.文本.排序'),
          dataIndex: 'sort',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('活动记录.创建时间'),
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getAtvCateList();
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    moment,
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 查询活动记录列表 */
    getList () {
      this.loading = true
     pageActivity(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    getAtvCateList() {
      listType().then(response => {
        this.activityTypeList = response.data;
      })
    },
    getCategoryNameById(id) {
      if (!id) {
        return "";
      }
      let cateInfo = this.activityTypeList.find(e => e.id === id);
      if (cateInfo) {
        return cateInfo.name
      } else {
        return "";
      }
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        activityType: undefined,
        activityName: undefined,
        startTime: undefined,
        endTime: undefined,
        coverPic: undefined,
        posterPic: undefined,
        posterTxt: undefined,
        levelIds: undefined,
        limitNum: undefined,
        description: undefined,
        status: undefined,
        sort: undefined,
        area: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    handleRank(record) {
      this.$router.push({
        path: 'activityRank',
        query:
          {
            id: record.id
          }
      })
    },

    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids = []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('通用.文本.确认删除所选中数据'),
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delActivity(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              const message = that.$t('通用.文本.删除成功')
              that.$message.success(
                message,
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk () {
          that.download('activity/activity/export',
            that.queryParam
          , `活动记录_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    handleSelectExport () {
      var that = this
      let queryParam = {"idList": this.ids}
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出选中数据'),
        onOk () {
          that.download('activity/activity/export', queryParam, `活动记录_${new Date().getTime()}.xlsx`)
          that.ids = []
          that.multiple=true
          that.selectedRowKeys=[]
        },
        onCancel () {
          that.ids = []
          that.multiple=true
          that.selectedRowKeys=[]
        }
      })
    }
  }
}
</script>
